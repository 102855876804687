export const host = {
	production:
		'https://app-xde-datadirectui-cus-p.ase-xde-cus-p.appserviceenvironment.net',
	prodExtHost: 'https://xde.northerntrust.com',
	uat:
		'https://app-xde-datadirectui-cus-u.ase-xde-cus-u.appserviceenvironment.net',
	uatExtHost: 'https://xde.uat.northerntrust.com',
	qa:
		'https://app-xde-datadirectui-cus-q.ase-xde-cus-d.appserviceenvironment.net',
	qaExtHost: 'https://xde.dev.northerntrust.com',
	development:
		'https://app-xde-datadirectui-cus-d.ase-xde-cus-d.appserviceenvironment.net',
	devExtHost: 'https://xde.dev.northerntrust.com',
};

const port = window.location.port ? `:${window.location.port}` : '';
const root = `${window.location.protocol}//${window.location.hostname}${port}/`;

export const Constants = {
	stsAuthority: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
	authorisationEndpoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/authorize',
	clientId: '0oa1v82s063gp81tZ0h8',
	clientRoot: root,
	clientScope: 'openid profile offline_access',
	apiRoot: root,
	type: 'code',
	feedbackUrl:
		'https://northerntrustfeedback.allegiancetech.com/cgi-bin/qwebcorporate.dll?idx=M9EVF9',
};
export const ENVIRONMENT = {
	DEV: {
		clientId: '0oa1v82s063gp81tZ0h8',
		clientId_External: '0oa1v9yym5kGeGGKj0h8',
		stsAuthority: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
		authEndpoint: "https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/authorize",
		iKey: '3f7aafbe-cd16-4b25-80b8-0bef8f356d32',
		endSessionEndPoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/logout',
		tokenEndpoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/token',
		issuer: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
	},
	QA: {
		clientId: '0oa1v82s063gp81tZ0h8',
		clientId_External: '0oa1v9yym5kGeGGKj0h8',
		stsAuthority: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
		authEndpoint: "https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/authorize",
		iKey: '3f7aafbe-cd16-4b25-80b8-0bef8f356d32',
		endSessionEndPoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/logout',
		tokenEndpoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/token',
		issuer: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
	},
	UAT: {
		clientId: '0oa1v82s063gp81tZ0h8',
		clientId_External: '0oa1v9yym5kGeGGKj0h8',
		stsAuthority: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
		authEndpoint: "https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/authorize",
		iKey: '3f7aafbe-cd16-4b25-80b8-0bef8f356d32',
		endSessionEndPoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/logout',
		tokenEndpoint: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7/v1/token',
		issuer: 'https://loginuat.ntrs.com/oauth2/austt2ltc0NdZ2J8K0h7',
	},
	PROD: {
		clientId: '0oa1vanhclivslw660h8',
		clientId_External: '0oa1vgowd4xi95Cef0h8',
		stsAuthority: 'https://login.ntrs.com/oauth2/aus1vanhgzaLOxzl00h8',
		authEndpoint: "https://login.ntrs.com/oauth2/aus1vanhgzaLOxzl00h8/v1/authorize",
		iKey: '3f7aafbe-cd16-4b25-80b8-0bef8f356d32',
		endSessionEndPoint: 'https://login.ntrs.com/oauth2/aus1vanhgzaLOxzl00h8/v1/logout',
		tokenEndpoint: 'https://login.ntrs.com/oauth2/aus1vanhgzaLOxzl00h8/v1/token',
		issuer: 'https://login.ntrs.com/oauth2/aus1vanhgzaLOxzl00h8',

	},
};

export const PERFORMANCE_COMMON = {
	ORIENTATION: {
		groupId: 'DisplayOrientation',
		colId: 'DisplayOrientation',
		colText: 'Display Orientation',
		fieldType: 'RadioButton',
		colHelp:
			'Choose to display investment and benchmark returns in separate rows or in separate columns.',
	},
	FREQUENCY: {
		groupId: 'ValuationFrequency',
		colId: 'ValuationFrequency',
		colText: 'Valuation Frequency',
		fieldType: 'RadioButton',
		colHelp: 'Service level dependant, daily available data service',
	},
	OPTIONS_TOOLTIP: {
		rowByRowMsg:
			'Displays investment and benchmark returns in separate rows.',
		sideBySideMsg:
			'Displays investment and benchmark returns in separate columns.',
	},
	DISPLAY_ORIENTATION: 'DISPLAYORIENTATION',
	PERFORMANCE_IDENTIFIER: 'PerformanceIdentifier',
	PERFORMANCE_OBJECT: 'PerformanceObject',
	MULTI_REF_CURRENCY: 'MultiRefCurrency',
	REFRENCE_DATE: 'ReferenceDate',
	TIME_PERIOD: 'TimePeriod',
	RADIO_BUTTON: 'RadioButton',
	DATE_RANGE: 'DateRange',
	CALENDAR_MONTH_TIME_PERIOD: 'CalendarMonthTimePeriods',
	TIME_PERIOD_Single: 'TimePeriodSingle',
	CTR_SERVICE_NAME: 'Contribution to Return',
	INDICES_SERVICE_NAME: 'Indices',
	INDICES_FCTP_SERVICE_NAME: 'Indices For CustomTime Periods',
	INDICES_FCTP: "Indices for CustomTime Periods",
	RFCTPRBR: "Rates for Custom Time Periods Row by Row",
	RFCTPSBS: "Rates for Custom Time Periods Side by Side",
	RFCTP: "Rates for Custom Time Periods",
	ESLAM: "Equity Stock Level Attribution Monthly",
	ESLAD: "Equity Stock Level Attribution Daily"
};
export const dateFormatText = {
	dateTimeFormat: 'mm/dd/yyyy hh:mm:ss',
	dateValueFormat: 'mm/dd/yyyy',
};

export const uuIdPattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

export const ACCOUNTINGSERVICES = [
	'Positions Report',
	'Transaction Report',
	'Valuation Details Report',
];

export const INDECESERVICES = [
	'Currency',
	'Index Type',
	'CountryRegion',
	'AssetClass',
	'Institution',
	'searchText',
	'searchBox'
];
export const RFCTP_SERVICES = [
	"Rates for Custom Time Periods Row by Row",
	"Rates for Custom Time Periods Side by Side",
	"Rates for Custom Time Periods",
	"Rates for Custom Time Periods  Row by Row"
];
export const INDECESERVICENAME = [
	'Indices',
	'Indices For CustomTime Periods',
	"Indices for CustomTime Periods"
];

export const EXTERNALROOTURL = [
	'https://xde.dev.northerntrust.com/',
	'https://xde.uat.northerntrust.com/',
	'https://xde.northerntrust.com/'
];

export const INTERNALROOTURL = [
	'https://app-xde-datadirectui-cus-u.ase-xde-cus-u.appserviceenvironment.net/',
	'https://app-xde-datadirectui-cus-q.ase-xde-cus-d.appserviceenvironment.net/',
	'https://app-xde-datadirectui-cus-p.ase-xde-cus-p.appserviceenvironment.net/'
];