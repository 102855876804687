import axios from 'axios';

import { get } from 'lodash';
import { config, checkEnv } from 'utils/config';
import { updateRibbon, signOutOnSessionTimeOut} from 'utils/excelUtils/office-apis-helpers';
import { getLocalStorageItem, updateLocalStorage } from 'utils/CommonUtil';
import { ENVIRONMENT, EXTERNALROOTURL, Constants } from 'utils/Constants';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createHashHistory } from 'history';
import { getAppInsights } from 'utils/AppInsights/TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getGlobal } from '../commands/commands';
import { AuthService } from '../services/AuthService';
import { Api } from 'api/Api';
//import { signOut } from '../containers/login/LoginAction';

const g = getGlobal();

const history = createHashHistory();
const loginPath = '/login';
const redirectPath = currentURL => {
	g.state.isMenuEnabled = false;
	updateRibbon();
	const objectData = {
		pathname: loginPath,
		search: currentURL,
		state: currentURL,
	};
	history.replace(objectData);
	history.push(objectData);
};
const axiosInstance = axios.create({
	baseURL: get(config, 'serverUrl'),
	// timeout: 300000,
});
const redirectPathUrl = () => {
	//g.state.isSignedIn = false;
	//updateLocalStorage('loggedIn', 'no');
	//localStorage.removeItem('authToken');
	//localStorage.removeItem('id_token');
	//localStorage.removeItem('refresh_token');
	const currentURL = window.location.hash;
	if (currentURL !== `#${loginPath}`) {
		redirectPath(currentURL);
	}
};

const renewToken = () => {

	Api.getRefreshToken()
	.then(json => {
		console.log("refresh token response  :::",json);
		localStorage.setItem('authToken',json.data.access_token);
		updateLocalStorage('authToken',json.data.access_token);
		localStorage.setItem('refresh_token', json.data.refresh_token);
		updateLocalStorage('refresh_token', json.data.refresh_token);
		localStorage.setItem('id_token', json.data.id_token);
		updateLocalStorage('id_token', json.data.id_token);
		})
	.catch(error =>
		console.log("error in resresh token ::",error),
	);


/*	const authService = new AuthService();
	authService.renewToken().then(user => {
		console.log("AxiosInstance  renewToken :::",user);
		if (user && user.access_token) {
			updateLocalStorage('authToken', get(user, 'access_token'));
			updateLocalStorage('refresh_token', get(user, 'refresh_token'));
			updateLocalStorage('id_token', get(user, 'id_token'));
		}
	});*/
};

let timeout;
const throttle = (func, limit) => {
	if (!timeout) {
		func();
		timeout = setTimeout(function() {
			timeout = undefined;
		}, limit);
	}
};

axiosInstance.interceptors.request.use(configProp => {
	const configData = configProp;
	const validRootURL = Constants.clientRoot;
	const flag = EXTERNALROOTURL.includes(validRootURL) ? 'External' : 'Internal';
	console.log("True:External and False:Internal  flag :",flag);
	const token = getLocalStorageItem('authToken');
	const refreshToken = getLocalStorageItem('refresh_token');
	const Headerdata = {Ntrsusertype:flag, refresh_token:refreshToken};
	if (token) {
	    configData.headers = {...Headerdata};
		configData.headers.Authorization = `${token}`;
	} else {
	g.state.isSignedIn = false;
	updateLocalStorage('loggedIn', 'no');
	localStorage.removeItem('authToken');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('id_token');
	redirectPathUrl();


	}

	return configData;
});

axiosInstance.interceptors.response.use(
	response => {
		throttle(renewToken, 10000);
		return response;
	},
	error => {
		const appInsights = getAppInsights();
		appInsights.trackException({
			error,
			severityLevel: SeverityLevel.Error,
		});
		if (error.response.status === 401 || error.response.status === 403) {

			const env = checkEnv();
			const validRootURL = Constants.clientRoot;
			const flag = EXTERNALROOTURL.includes(validRootURL) ? true : false;
			const clientIDs = flag ? ENVIRONMENT[env].clientId_External : ENVIRONMENT[env].clientId;
			console.log("clientIDs Logout LoginContainer 401 :",clientIDs)
			console.log("Logout LoginContainer True:External and False:Internal  flag 401 :",flag)
			window.localStorage.removeItem(
				`oidc.user:${ENVIRONMENT[env].stsAuthority}:${clientIDs}`,
			);
			window.sessionStorage.removeItem('loggedIn');

			localStorage.setItem('loggedIn', 'no');
			localStorage.removeItem('authToken');
			localStorage.removeItem('id_token');
			localStorage.removeItem('refresh_token');
			g.state.isSignedIn = false;
			g.state.isMenuEnabled = false;

		
			//signOutOnSessionTimeOut();
			redirectPathUrl();    

		/*	const env = checkEnv();
		//	const loginContainer = new LoginContainer();
			const validRootURL = Constants.clientRoot;
		
			const flag = EXTERNALROOTURL.includes(validRootURL) ? true : false;
			const clientIDs = flag ? ENVIRONMENT[env].clientId_External : ENVIRONMENT[env].clientId;
			console.log("clientIDs Logout LoginContainer  :",clientIDs)
			console.log("Logout LoginContainer True:External and False:Internal  flag :",flag)
			window.localStorage.removeItem(
				`oidc.user:${ENVIRONMENT[env].stsAuthority}:${clientIDs}`,
			);
			window.sessionStorage.removeItem('loggedIn');

			localStorage.setItem('loggedIn', 'no');
			localStorage.removeItem('authToken');
			localStorage.removeItem('id_token');
			localStorage.removeItem('refresh_token');
			g.state.isSignedIn = false;
			g.state.isMenuEnabled = false;
			//loginContainer.logout();
			*/
		}
		return Promise.reject(error);
	},
);

export default axiosInstance;
