/* global Excel, Office */
import 'regenerator-runtime/runtime';
import { formatDate } from 'utils/dateUtil';
import { validateCellValue } from 'utils/validationRules';
import {
	updateSessionStorage,
	getSessionStorageItem,
	updateLocalStorage,
} from 'utils/CommonUtil';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createHashHistory } from 'history';
import { get, toUpper, isEmpty, filter, map, maxBy, isString } from 'lodash';
import { INDECESERVICES } from 'utils/Constants';

const headerFormat = range => {
	const borders = get(range, 'format.borders');
	range.format.fill.color = '#2d8031';
	range.format.font.color = '#ffffff';
	borders.getItem('InsideHorizontal').style = 'Continuous';
	borders.getItem('InsideVertical').style = 'Continuous';
	borders.getItem('EdgeBottom').style = 'Continuous';
	borders.getItem('EdgeLeft').style = 'Continuous';
	borders.getItem('EdgeRight').style = 'Continuous';
	borders.getItem('EdgeTop').style = 'Continuous';
	range.format.autofitColumns();
};

const colName = col => {
	const ordA = 'A'.charCodeAt(0);
	const ordZ = 'Z'.charCodeAt(0);
	const len = ordZ - ordA + 1;

	let endPoint = '';
	while (col >= 0) {
		endPoint = String.fromCharCode((col % len) + ordA) + endPoint;
		col = Math.floor(col / len) - 1;
	}
	return endPoint;
};

const clearExcelData = (removeRange, removeSheet) => {
	if (removeRange && removeSheet) {
		Excel.run(context => {
			const sheet = context.workbook.worksheets.getItem(removeSheet);
			const range = sheet.getRange(removeRange);
			range.clear();
			return context.sync();
		});
	}
};

const clearExcelSheetData = () => {
	Excel.run(function (context) {
		var sheet = context.workbook.worksheets.getActiveWorksheet();
		sheet.getRange().clear();
		return context.sync();
	}).catch(function(error) {
		console.log("Error: " + error);
		if (error instanceof OfficeExtension.Error) {
			console.log("Debug info: " + JSON.stringify(error.debugInfo));
		}
	});
};

const moveToCellSheet = (cellValue, sheetValue) => {
	// const sheetName = sheetValue !== '' ? sheetValue : 'Sheet1';
	let sheet;
	Office.onReady(() => {
		Excel.run(context => {
			const { worksheets } = get(context, 'workbook');
			if (sheetValue) {
				sheet = worksheets.getItem(sheetValue);
			} else {
				sheet = worksheets.getActiveWorksheet();
			}
			const cellId = cellValue !== '' ? cellValue : 'A1';
			const range = sheet.getRange(cellId);
			range.select();
			sheet.activate();
			sheet.load('name');
			return context.sync();
		});
	});
};

const moveToSheet = sheetValue => {
	const sheetName = sheetValue !== '' ? sheetValue : 'Sheet1';
	Office.onReady(() => {
		Excel.run(context => {
			const sheet = context.workbook.worksheets.getItem(sheetName);
			sheet.activate();
			sheet.load('name');
			return context.sync().then(() => {
				return sheet.name;
			});
		});
	});
};

const spiltCellValue = splitValue => {
	const parts = splitValue && splitValue.match(/([A-Za-z]+)([0-9]+)/);
	const cellColumn = parts && parts[1];
	const cellRow = parts && parseInt(parts[2], 10);
	return { cellColumn, cellRow };
};

// Function to split First Row Cell value
export const spiltTopRowCellValue = splitValue => {
	const parts = splitValue && splitValue.match(/([A-Za-z]+)([0-9]+)/);
	const cellRow = parts && parts.length > 1 ? parts[2] : null;
	return cellRow === '1';
};

// Object to update Cell Value and Sheet Name
const updateCellValueSheetName = {
	cellValue: null,
	sheetName: null,
	isFirstRowSelected: false,
	cellRangeValue: null,
	sheetRangeName: null,
};

const onInputCellChange = event => {
	let cellValue = '';
	let sheetName = '';
	let cellRangeValue= '';
	let sheetRangeName= '';
	let isValidCellId = true;
	let isValidSheetName = true;
	const { name, value } = event.target;
	let isInputCellValid = true;
	let isFirstRowSelected = false;
	switch (toUpper(name)) {
		case 'CELLNUMBER':
			updateCellValueSheetName.cellValue = value;
			isValidCellId = validateCellValue(value);
			updateCellValueSheetName.isFirstRowSelected = spiltTopRowCellValue(
				value,
			);
			isInputCellValid = value;
			if (isValidCellId) {
				moveToCellSheet(value);
			}
			break;
		case 'SHEETNAME':
			updateCellValueSheetName.sheetName = value;
			isValidSheetName = value !== '';
			moveToSheet(value);
			break;
		case 'CELLRANGENUMBER':
			updateCellValueSheetName.cellRangeValue = value;
			break;
		case 'SHEETRANGENAME':
			updateCellValueSheetName.sheetRangeName = value;
			break;
	}
	cellValue = updateCellValueSheetName.cellValue;
	sheetName = updateCellValueSheetName.sheetName;
	cellRangeValue = updateCellValueSheetName.cellRangeValue;
	sheetRangeName= updateCellValueSheetName.sheetRangeName;
	isFirstRowSelected = updateCellValueSheetName.isFirstRowSelected;
	return {
		cellValue,
		sheetName,
		isValidSheetName,
		isInputCellValid,
		isValidCellId,
		isFirstRowSelected,
		cellRangeValue,
		sheetRangeName,
	};
};

const cellSheetPopulation = setCellSheet => {
	const excelValue = {};
	Office.onReady(() => {
		const document = get(Office, 'context.document');
		if (document) {
			document.removeHandlerAsync(
				Office.EventType.DocumentSelectionChanged,
				{},
				result => {
					if (result.status === Office.AsyncResultStatus.Succeeded) {
						document.addHandlerAsync(
							Office.EventType.DocumentSelectionChanged,
							() => {
								Excel.run(context => {
									const sheetRange = context.workbook.getSelectedRange();
									const activeSheet = context.workbook.worksheets.getActiveWorksheet();
									sheetRange.load('address');
									sheetRange.load('values');
									activeSheet.load('name');
									return context.sync().then(() => {
										const { address, values } = sheetRange;
										const { name } = activeSheet;
										// const { address } = activeCell;
										const sheetNameId = address.split('!');
										if (sheetNameId[1] !== '1:1048576') {
											const cellValue = sheetNameId[1];
											const newVal = values[0][0];
											excelValue.rangeValue = newVal;
											excelValue.sheetName = name;
											excelValue.cellValue = cellValue;
											excelValue.isInputCellValid = cellValue;
											excelValue.isFirstRowSelected = spiltTopRowCellValue(
												cellValue,
											);
											setCellSheet(excelValue);
										}
									});
								});
							},
						);
					}
				},
			);
		}
	});
};

// removed spread sheet selection event on next click
const removeAllEventHandlers = () => {
	try {
		Excel.run(context => {
			Office.context.document.removeHandlerAsync(
				Office.EventType.DocumentSelectionChanged,
			);
			return context.sync().then(() => {
				return true;
			});
		});
	} catch (error) {
		return error;
	}
	return true;
};

const updateRangeValue = (key, data) => {
	if (key === 'ReferenceDate') {
		return formatDate(data[key].value, 'mm/dd/yyyy');
	}
	return data[key].value.text;
};

// returns true/false based on condition
const isCTP = (objValue, keyItem) => {
	return objValue && (objValue.key === 'CTP' || keyItem === 'CTP');
};

// method returns string for FundAccounting return StartDate/EndDate and for MVS CTP.StartDate/CTP.EndDate.
const colTextValue = (keyItem, text) => {
	return keyItem === 'CTP' ? text : `CTP.${text}`;
};
// Update Dataset Cell Address
const getDatasetTitleCellValue = cellValue => {
	const dataSetTitleRange = spiltCellValue(cellValue);
	const dataSetTitleRow = dataSetTitleRange.cellRow - 1;
	const dataSetTitleColumn = dataSetTitleRange.cellColumn;
	return `${dataSetTitleColumn}${dataSetTitleRow}`;
};
// Clear Display Setting from Cell
export const clearRenderTitleExcel = () => {
	const startCellValue = getSessionStorageItem('startCellValue');
	const removeSheet = getSessionStorageItem('removeSheetName');
	if (removeSheet) {
		const removCellValue = getDatasetTitleCellValue(startCellValue);
		clearExcelData(removCellValue, removeSheet);
	}
};

// Display Setting page Data Population
const populateTitleToExcel = (data, cellValue, sheetName) => {
	Excel.run(context => {
		const sheet = context.workbook.worksheets.getItem(sheetName);
		const startRange = sheet.getRange(cellValue);
		startRange.values = data;
		startRange.format.autofitColumns();
		return context.sync();
	}).catch(err => {
		return err;
	});
};
const ctpKeyValidate = key => {
	return key === 'TimePeriod' || key === 'FromDate' || key === 'CTP1';
};
const updateInputCellStorage = (col, endRange) => {
	updateSessionStorage('inputCellValueRemove', `${colName(col)}${endRange}`);
};
const isClearRenderTitle = isGetDataClicked => {
	if (!isGetDataClicked) {
		clearRenderTitleExcel();
	}
};
const dataRangeUpdate = (data, range, key) => {
	//1163204:Index Search
	if( key === 'IndexPerformanceObject'){
		if (Array.isArray(data[key].value.key)) {
			range.values = Array.prototype.map
			.call(data[key].value.key, item => {
				return item.label;
			})
			.join(',\r\n');
		}
	} else {
	if (Array.isArray(data[key].value)) {
		range.values = Array.prototype.map
			.call(data[key].value, item => {
				return item.text;
			})
			.join(',\r\n');
	} else {
		const valueKey = updateRangeValue(key, data);
		range.values = valueKey ? updateRangeValue(key, data) : data[key].value;
	}}
};
const getStartRange = (isGetDataClicked, sheet, inputCellNo, context) => {
	let startRange;
	if (isGetDataClicked) {
		sheet.activate();
		sheet.load('name');
		startRange = sheet.getRange(inputCellNo);
		// startRange.select();
		startRange.load('rowIndex');
	} else {
		startRange = context.workbook.getSelectedRange().load('rowIndex');
	}
	startRange.load('columnIndex');
	return startRange;
};
const isValidData = (data, key) => {
	const dataLength = data[key].value !== '' || data[key].value.length > 0;
	return data[key] && data[key].value && dataLength;
};
const getStartDate = (data, objValue) => {
	return get(data, 'CTP1.startDate.value') || objValue.startDate;
};
const getEndDate = (data, objValue) => {
	return get(data, 'CTP1.endDate.value') || objValue.endDate;
};
const dataPopulationInput = (
	data,
	sheetName,
	inputCellNo,
	isGetDataClicked,
) => {
	isClearRenderTitle(isGetDataClicked);
	Excel.run(context => {
		const sheet = context.workbook.worksheets.getItem(sheetName);
		const startRange = getStartRange(
			isGetDataClicked,
			sheet,
			inputCellNo,
			context,
		);
		return context
			.sync()
			.then(() => {
				const row = startRange.rowIndex;
				let col = startRange.columnIndex;
				let isSingleTimePeriod;
				const CTP = 'Custom Time Periods';
				const SCTP = 'TimePeriodSingle';
				let isFrequencyExist = false;
				const flag = data?.IndexPerformanceObject?.colText === 'Index Search';
				const Arrayflg = data?.PerformanceObjectType?.colText === "Performance Object Type";
				const Arrayfeatur = data['ArrayFeature']?.value?.key;

				Object.keys(data).map(key => {
					//1163204:Index Search
					if(flag && INDECESERVICES.includes(key)) return null; 
					if('Account/Group/Template' === data[key].colText || ("Performance Object Type" === data[key].colText && 'NO'=== Arrayfeatur ) ) return null; 

					if (key === SCTP) {
						isSingleTimePeriod = key;
					}

					if('Frequency' === data[key].colText){
						if('NA'===data[key].value.key){
						   isFrequencyExist = true;
						}
					}
					const validDataForExcel = isValidData(data, key);
					if (validDataForExcel) {
						let range = sheet.getCell(row, col);
						if (ctpKeyValidate(key) && isSingleTimePeriod !== SCTP) {
							const inputTimePeriod = data[key].value;
							Object.keys(inputTimePeriod).map(keyItem => {
								const objValue = inputTimePeriod[keyItem];
								let ctpColRange = sheet.getCell(row, col);
								if (isCTP(objValue, keyItem)) {
									if(isFrequencyExist){
										return null;
									}
									ctpColRange.values = colTextValue(
										keyItem,
										'StartDate',
									);
									const startDate = getStartDate(
										data,
										objValue,
									);
									const endDate = getEndDate(data, objValue);
									headerFormat(ctpColRange);
									ctpColRange = sheet.getCell(row + 1, col);
									ctpColRange.values = formatDate(
										startDate,
										'mm/dd/yyyy',
									);
									ctpColRange.format.autofitColumns();
									col += 1;
									ctpColRange = sheet.getCell(row, col);
									ctpColRange.values = colTextValue(
										keyItem,
										'EndDate',
									);
									headerFormat(ctpColRange);
									ctpColRange = sheet.getCell(row + 1, col);
									ctpColRange.values = formatDate(
										endDate,
										'mm/dd/yyyy',
									);
								} else {
									ctpColRange.values =
										inputTimePeriod[keyItem].text;
									headerFormat(ctpColRange);
									ctpColRange = sheet.getCell(row + 1, col);
									ctpColRange.values = 'Y';
								}
								ctpColRange.format.autofitColumns();
								const endRange = row + 2;
								updateInputCellStorage(col, endRange);
								col += 1;
								return null;
							});
						} else if (key === SCTP) {
							let ctpColRange = sheet.getCell(row, col);

							if (data[key].value.key === 'CTP') {
								ctpColRange.values = colTextValue(
									data[key].value.key,
									'CTP.StartDate',
								);
								const startDate = get(data, 'CTP1.startDate.value');

								const endDate = get(data, 'CTP1.endDate.value');
								headerFormat(ctpColRange);
								ctpColRange = sheet.getCell(row + 1, col);
								ctpColRange.values = formatDate(
									startDate,
									'mm/dd/yyyy',
								);
								ctpColRange.format.autofitColumns();
								col += 1;
								ctpColRange = sheet.getCell(row, col);
								ctpColRange.values = colTextValue(
									data[key].value.key,
									'CTP.EndDate',
								);
								headerFormat(ctpColRange);
								ctpColRange = sheet.getCell(row + 1, col);
								ctpColRange.values = formatDate(
									endDate,
									'mm/dd/yyyy',
								);
							} else {
								ctpColRange.values = data[isSingleTimePeriod].value.text;
								headerFormat(ctpColRange);
								ctpColRange = sheet.getCell(row + 1, col);
								ctpColRange.values = 'Y';
							}
							ctpColRange.format.autofitColumns();
							const endRange = row + 2;
							updateInputCellStorage(col, endRange);
							col += 1;
							return null;
						} else {
							if('Frequency' === data[key].colText){
								 if('NA'===data[key].value.key){
									isFrequencyExist = true;
									return null;
								 }else{
									range.values = data[key].colText;
									headerFormat(range);
									range = sheet.getCell(row + 1, col);
									dataRangeUpdate(data, range, key);
									range.format.autofitColumns();
									const endRange = row + 2;
									updateInputCellStorage(col, endRange);
									col += 1;	 
								 }
								 return null;
							}else if (data[key].colText !== CTP ) {
								range.values = data[key].colText;
								headerFormat(range);
								range = sheet.getCell(row + 1, col);
								dataRangeUpdate(data, range, key);
								const endRange = row + 2;
								updateInputCellStorage(col, endRange);
								range.format.columnWidth = 230;
								range.format.wrapText = true;
								range.format.autofitColumns()
								col += 1;
							}
						}
					}
					return null;
				});
				context.sync();
			})
			.catch(err => {
				return err;
			});
	}).catch(err => {
		return err;
	});
};

const dataPopulationOutput = (
	data,
	outputCellNo,
	outputSheetName,
	isGetDataClicked,
) => {
	Excel.run(context => {
		let sheet;
		let startRange;
		const { worksheets } = get(context, 'workbook');
		if (isGetDataClicked) {
			sheet = worksheets.getItem(outputSheetName);
			sheet.activate();
			sheet.load('name');
			startRange = sheet.getRange(outputCellNo);
			startRange.load('rowIndex');
		} else {
			sheet = worksheets.getActiveWorksheet();
			sheet.load('name');
			startRange = context.workbook.getSelectedRange().load('rowIndex');
		}
		startRange.load('columnIndex');

		return context
			.sync()
			.then(() => {
				const datasheet = worksheets.getItem(sheet.name);
				const row = startRange.rowIndex;
				let col = startRange.columnIndex;

				data.map(item => {
					if (item.selected) {
						const range = datasheet.getCell(row, col);
						range.values = item.colText;
						headerFormat(range);
						const endRange = row + 1;
						updateSessionStorage(
							'endOutputCellValue',
							`${colName(col)}${endRange}`,
						);
						col += 1;
					}
					return null;
				});
				context.sync();
			})
			.catch(err => {
				return err;
			});
	}).catch(err => {
		return err;
	});
};

// below method rearrange API response order with output headers rendered in excel
const arrangeAPIRes = (apiResponse, outputHeaders) => {
	const outputRow = [];
	apiResponse.map(resItm => {
		let response = [];
		outputHeaders.map(headerItem => {
			const item =
				resItm[headerItem.label] !== undefined
					? resItm[headerItem.label]
					: resItm[headerItem.value];
			const itemVal = item || item === 0 ? item : '';
			const newItemVal =
				isString(itemVal) && itemVal.indexOf('\\') !== -1
					? itemVal.replace('\\', '\\\\')
					: itemVal;

			response = [...response, newItemVal];
			return null;
		});
		outputRow.push(response);
		return null;
	});
	return outputRow;
};

// below method populate API response into identified range for output
const ExcelGetData = (
	dataApi,
	outputHeaders,
	callBack,
	excelDataOutput,
	datasetId,
	refreshCellValue,
) => {
	const outputCellNo = get(excelDataOutput, 'outputCellNo');
	const endOutputCellValue = get(excelDataOutput, 'endOutputCellValue');
	const outputDatsetSheetName = get(excelDataOutput, 'outputSheetName');
	const outputSheetName = excelDataOutput
		? outputDatsetSheetName
		: getSessionStorageItem('removeOutputSheetName');
	const outputStartCellValue = excelDataOutput
		? outputCellNo
		: getSessionStorageItem('startOutputCellValue');
	const outputEndCellValue = excelDataOutput
		? endOutputCellValue
		: getSessionStorageItem('endOutputCellValue'); // outputCellValueRemove
	const apiResponse = get(dataApi, 'results.resultRow');
	const outputData = arrangeAPIRes(apiResponse, outputHeaders);
	const outputStartRange = getSessionStorageItem('noBtnClickFlag') === 'YES'? spiltCellValue(getSessionStorageItem('outputCellValue_old')) : spiltCellValue(outputStartCellValue);
	const outputEndRange = spiltCellValue(outputEndCellValue);
	const startRow = outputStartRange.cellRow + 1;
	const startRange = `${outputStartRange.cellColumn}${startRow}`;
	const endRow = outputEndRange.cellRow + outputData.length;
	const endRange = `${outputEndRange.cellColumn}${endRow}`;

	Excel.run(context => {
		const sheet = context.workbook.worksheets.getItem(outputSheetName);
		return context
			.sync()
			.then(() => {
				const range = sheet.getRange(`${startRange}:${endRange}`);
				updateLocalStorage(
					'outputDataRange',
					`${startRange}:${endRange}!${outputSheetName}`,
				);
				range.values = outputData;
				range.format.autofitColumns();
				sheet.getRange(getSessionStorageItem("getDataCellValue") || refreshCellValue).format.columnWidth = 161;
				return context.sync();
			})
			.then(() => {
				const newRange = `${startRange}:${endRange}!${outputSheetName}`;
				callBack(newRange, datasetId);
			});
	}).catch(err => {
		return err;
	});
};
const updateHiddenSheet = (sheets, sheetName, uniqueId) => {
	const sheet = sheets.add(sheetName);
	const uniqueIdRange = sheet.getRange('A1');
	uniqueIdRange.values = uniqueId;
	uniqueIdRange.format.autofitColumns();
	sheet.visibility = Excel.SheetVisibility.hidden;
	sheet.load('name');
};

const serviceObject = (selectedServiceName, reportTitle, servicePath) => {
	return {
		id: '154',
		level: '0',
		name: selectedServiceName,
		endpoint: servicePath,
		serviceMethod: 'getReportData',
		help: reportTitle,
	};
};
const createReportObject = (reportDataParameter, existingReport) => {
	const {
		uuId,
		inputCellUpdate,
		outputCellUpdate,
		selectedServiceName,
		reportTitle,
		serviceNameReportTitle,
		displaySettingObject,
		serviceData,
		datasetRange,
		selectedServiceId,
		duplicateClicked,
	} = reportDataParameter;
	let dataSetOrder = null;
	let countDataset = 1;
	if (!isEmpty(existingReport)) {
		const maxOrderValue = maxBy(existingReport, item => {
			if (item.reportId === selectedServiceId && !duplicateClicked) {
				dataSetOrder = item.dataSetOrder;
			}
			return item.dataSetOrder;
		});
		const newMaxValue = maxOrderValue.dataSetOrder;
		countDataset = newMaxValue + 1;
	}
	delete serviceData.serviceDescription;
	const updatedDataset = filter(existingReport, item => {
		return item.reportId !== selectedServiceId || duplicateClicked;
	});
	const reports = !isEmpty(updatedDataset) ? [...updatedDataset] : [];
	reports.push({
		inputNodes: inputCellUpdate,
		outputNodes: outputCellUpdate,
		datasetRange,
		displaySetting: displaySettingObject,
		lastRefreshTime: new Date(),
		orientation: 'Horizontal',
		reportType: selectedServiceName,
		reportName: reportTitle,
		reportId:
			selectedServiceId && !duplicateClicked ? selectedServiceId : uuId,
		serviceData,
		service: serviceNameReportTitle,
		dataSetOrder: !dataSetOrder ? countDataset : dataSetOrder,
	});
	return map(reports, item => {
		delete item.duplicateClicked;
		return item;
	});
};

// Create Dataset Object
const datasetObject = itemList => {
	return {
		groupId: itemList.groupId,
		colId: itemList.colId,
		colText: itemList.colText,
		value: itemList.value,
		defaultValue: itemList.defaultValue,
		smartDateKey: itemList.smartDateKey,
		showCTP: itemList.showCTP,
		isMultiSelect: itemList.isMultiSelect,
	};
};

// Update Dataset Value
const updateDatasetKey = dataItem => {
	const hiddenSheetObj = [];
	dataItem.map(itemList => {
		hiddenSheetObj.push(datasetObject(itemList));
		return hiddenSheetObj;
	});
	return hiddenSheetObj;
};
const getDatasetObject = parameterObject => {
	const {
		inputNodes,
		outputArrangeNodes,
		uniqueId,
		uuId,
		selectedServiceName,
		reportTitle,
		servicePath,
		displaySettingObject,
		serviceData,
		existingDataSet,
		datasetRange,
		selectedServiceId,
		duplicateClicked,
	} = parameterObject;
	const existingReport = get(existingDataSet, 'reports');
	const sheetData = get(inputNodes, 'dataSheetDetail');
	const newInputSheetData = sheetData.split('!');
	//XDE-5631
	const inputCellValue = getSessionStorageItem('isInputCellNotChanged') === 'YES'? getSessionStorageItem('startCellValue_old') : newInputSheetData[0];
	updateSessionStorage('isInputCellNotChanged', 'NO');
	const inputSheetName = getSessionStorageItem('inputSheet_Change') === 'Y' ?  getSessionStorageItem('inputSheetName_Old') : newInputSheetData[1];
	//XDE-5631
	const outputCellValue = getSessionStorageItem('noBtnClickFlag') === 'YES'? getSessionStorageItem('outputCellValue_old') : getSessionStorageItem('outputCellValue');
	const outputSheetName = getSessionStorageItem('outputSheet_Change') === 'Y' ?  getSessionStorageItem('outputSheetName_Old') : getSessionStorageItem('outputSheetName');
	const inputCellValueRemove = getSessionStorageItem('inputCellValueRemove');
	const endOutputCellValue = getSessionStorageItem('endOutputCellValue');
	let sponsorCodeInputFields = '';
	if (inputNodes.sponsorCodeNode) {
		const itemList = get(inputNodes, 'sponsorCodeNode');
		sponsorCodeInputFields = datasetObject(itemList);
	}
	const getMandatoryNode = get(inputNodes, 'MandatoryFields');
	const mandatoryInputFields = getMandatoryNode
		? updateDatasetKey(getMandatoryNode)
		: updateDatasetKey(inputNodes.mandatoryFields);
	const getNonMandatoryNode = get(inputNodes, 'NonMandatoryFields');
	const nonMandatoryInputFields = getNonMandatoryNode
		? updateDatasetKey(getNonMandatoryNode)
		: updateDatasetKey(inputNodes.nonMandatoryFields);
	const inputCellUpdate = {
		inputCellNo: inputCellValue,
		inputSheetName,
		inputCellValueRemove,
		sponsorCodeNode: sponsorCodeInputFields,
		mandatoryFields: mandatoryInputFields,
		nonMandatoryFields: nonMandatoryInputFields,
	};
	if (!sponsorCodeInputFields) {
		delete inputCellUpdate.sponsorCodeNode;
	}
	const dataSetOutputCellValue = get(outputArrangeNodes, 'outputCellNo');
	const dataSetOutputSheetName = get(outputArrangeNodes, 'outputSheetName');
	const dataSetEndOutputCellValue = get(
		outputArrangeNodes,
		'endOutputCellValue',
	);
	const dataSetNonMandatoryFields = get(
		outputArrangeNodes,
		'nonMandatoryFields',
	);
	const outputCellUpdate = {
		outputCellNo: dataSetOutputCellValue || outputCellValue,
		outputSheetName: dataSetOutputSheetName || outputSheetName,
		endOutputCellValue: dataSetEndOutputCellValue || endOutputCellValue,
		nonMandatoryFields: dataSetNonMandatoryFields || outputArrangeNodes,
	};
	outputCellUpdate.nonMandatoryFields.map(item => {
		delete item.colHelp;
		return null;
	});
	const serviceNameReportTitle = serviceObject(
		selectedServiceName,
		reportTitle,
		servicePath,
	);
	const workBookName = 'Data Direct';
	const reportObjectParam = {
		uuId,
		inputCellUpdate,
		outputCellUpdate,
		selectedServiceName,
		reportTitle,
		serviceNameReportTitle,
		displaySettingObject,
		serviceData,
		datasetRange,
		selectedServiceId,
		duplicateClicked,
	};
	const reportObject = createReportObject(reportObjectParam, existingReport);
	const newObject = {
		nextReportIndex: '1',
		hiddenWorksheetID: uniqueId,
		id: uniqueId,
		sheetID: uniqueId,
		workbookName: workBookName,
		reports: reportObject,
	};
	return JSON.stringify(newObject);
};

const createHiddenSheet = (uniqueId, isRenameSheet) => {
	const sheetName = 'MetaData';
	Excel.run(context => {
		const sheets = get(context, 'workbook.worksheets');
		sheets.load('items/name');
		return context
			.sync()
			.then(() => {
				sheets.items.map((item, index) => {
					const lastSheet = sheets.items[index];
					if (lastSheet.name === sheetName) {
						const hiddenSheet = sheets.getItem(sheetName);
						const uniqueIdRange = hiddenSheet.getRange('A1');
						uniqueIdRange.values = uniqueId;
						uniqueIdRange.format.autofitColumns();
						hiddenSheet.visibility = Excel.SheetVisibility.hidden;
						// lastSheet.delete();
					}
					return false;
				});
				updateHiddenSheet(sheets, sheetName, uniqueId);
				if (!isRenameSheet) {
					const history = createHashHistory();
					history.push('/dataset-manager');
				}
				return context.sync();
			})
			.catch(err => {
				return err;
			});
	});
};

const getWorkbookId = callback => {
	return Office.onReady(() => {
		return Excel.run(context => {
			const sheet = context.workbook.worksheets.getItem('MetaData');
			const range = sheet.getRange('A1');
			range.load('values');
			return context
				.sync()
				.then(() => {
					callback(range.values);
				})
				.catch(err => {
					callback();
					return `error occured while reading workbook id${err}`;
				});
		});
	});
};

let eventResult;
let sheetRenameCallBack;

export const onSheetRename = async callBack => {
	await Excel.run(async context => {
		const sheets = context.workbook.worksheets;
		sheetRenameCallBack = callBack;
		sheets.load('items/name');
		sheets.load('name');
		sessionStorage.setItem('isRenameSheet', true);
		await context.sync();
		for (let i = 0; i < sheets.items.length; i += 1) {
			eventResult = sheets.items[i].onNameChanged.add(callBack);
		}
		await context.sync();
		console.log('Handler has been registered for the OnNameChanged event.');
	});
};

export const removeEvent = async () => {
	await Excel.run(eventResult && eventResult.context, async context => {
		if (eventResult) {
			eventResult.remove();
		}
		await context.sync();

		eventResult = null;
		console.log('Event handler successfully removed.');
	});
};

export const onWorksheetAdd = async () => {
	if (Excel) {
		await Excel.run(async context => {
			const activeSheet = context.workbook.worksheets.getActiveWorksheet();
			activeSheet.load('items/name');
			activeSheet.onNameChanged.add(sheetRenameCallBack);
			await context.sync();
			console.log(
				`Handler for worksheet onAdded event has been triggered.`,
			);
			await context.sync();
			console.log(
				'Handler has been registered for the OnNameChanged event.',
			);
		});
	}
};

export const registerOnAddHandler = async () => {
	if (Excel) {
		await Excel.run(async context => {
			const sheet = context.workbook.worksheets;
			sheet.onAdded.add(onWorksheetAdd);
			await context.sync();
			console.log('A handler has been registered for the OnAdded event.');
		});
	}
};

export {
	clearExcelData,
	cellSheetPopulation,
	dataPopulationInput,
	dataPopulationOutput,
	moveToCellSheet,
	moveToSheet,
	ExcelGetData,
	colName,
	createHiddenSheet,
	populateTitleToExcel,
	onInputCellChange,
	removeAllEventHandlers,
	getDatasetTitleCellValue,
	getDatasetObject,
	getWorkbookId,
	isCTP,
	colTextValue,
	spiltCellValue,
	clearExcelSheetData,
};
