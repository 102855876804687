export const PERFORMANCE = {
	INITIAL_STATE: 'INITIAL_STATE',
	MANIPULATE_INPUT_NODES: 'MANIPULATE_INPUT_NODES',
	BACK_BUTTON_CLICKED: 'BACK_BUTTON_CLICKED',
	UPDATE_VALUES: 'UPDATE_VALUES',
	VALIDATE_FIELDS: 'VALIDATE_FIELDS',
	CELL_CHANGE: 'CELL_CHANGE',
	CELL_RANGE_CHANGE: 'CELL_RANGE_CHANGE',
	ARRAY_FEATURE: 'ARRAY_FEATURE',
	ARRAY_VALIDATION: 'ARRAY_VALIDATION',
	SELECTED_ACCOUNT_LIST: 'SELECTED_ACCOUNT_LIST',
	UPDATE_RADIO_OPTIONS: 'UPDATE_RADIO_OPTIONS',
	DEFAULT_SERVICE_NAME: 'DEFAULT_SERVICE_NAME',
	UPDATE_BACK_WARNING: 'UPDATE_BACK_WARNING',
	UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
	FETCH_TEMPLATE_SERVICE: 'FETCH_TEMPLATE_SERVICE',
	FETCH_TEMPLATE_SERVICE_FAILURE: 'FETCH_TEMPLATE_SERVICE_FAILURE',
	OPEN_POPUP: 'OPEN_POPUP',
	READ_ONLY_TEXT: 'READ_ONLY_TEXT',
	DISPLAY_SETTING_CELL_CHANGED: 'DISPLAY_SETTING_CELL_CHANGED',
	FETCH_INDEX_SEARCH: 'FETCH_INDEX_SEARCH',
	SUCCESS_INDEX_SEARCH: 'SUCCESS_INDEX_SEARCH'
};
export const PERFORMANCEFORM_CONST = {
	PERFORMANCE_IDENTIFIER: 'PerformanceIdentifier',
	PERFORMANCE_IDENTIFIER_TMP: 'PerformanceIdentifierTMP',
	PERFORMANCE_OBJECT: 'PerformanceObject',
	MULTI_REF_CURRENCY: 'MultiRefCurrency',
	REFRENCE_DATE: 'ReferenceDate',
	TIME_PERIOD: 'TimePeriod',
	TEMPLATE: 'Template',
	CALENDAR_MONTH_TIME_PERIOD: 'CalendarMonthTimePeriods',
	TIME_PERIOD_Single: 'TimePeriodSingle',
	INDEX_PERFORMANCE_OBJECT: 'IndexPerformanceObject',
	UNIVERSE_PROVIDER: 'UniverseProvider',
	UNIVERSES: 'Universes',
	UNIVERSES_ACCOUNT_PAR: 'Universe Account Plan and Rankings',
	UNIVERSES_ID: 'UniverseId',
	COMPOSITE_NAME: 'CompositeName',
	COMPOSITE_GROUP_ID: 'CompositeGroupID',
	COMPOSITE_REPORTING: 'Composite Reporting',
	COMPOSITE_CTP: 'Composite Reporting for Custom Time Periods'	
};

export const INDEX_DROPDOWN_LOADING_NA = [
	'CurrencyMode',
	'BenchmarkBreakdown',
	'Index List',
	'InputFrequency'
];

export const UNIVERSE_DROPDOWN_LOADING_NA = [
	'RoRCategoryPreferenceUNI',
	'DisplayOptionUNI',
	'UniverseId',
	'Universe Name',
	'Bchmk',
	'PerformanceIdentifier',
	'PerformanceObject',
	'Account/Group',
	'Master Trust',
	'Benchmark Display'
];

export const COMPOSITE_DROPDOWN_LOADING_NA = [
	"CompositeName",
	"Composite Name",
	'Sponsor/Client Name'
];

export const ARRAY_DROPDOWN_NA = [
	'Master Trust',
	'Account/Group'
];
//,	'Array Ranges'
export const ARRAY_DROPDOWN_CONDITINAL = [
	'Master Trust',
	'Account/Group'
];

export const ARRAY_VAL_CONDITINAL = [
'Array Ranges',
'Performance Object Type'
];