import { Api } from 'api/Api';
import { updateSessionStorage } from 'utils/CommonUtil';
import { createAction } from 'utils/helpers';
import { createHiddenSheet } from 'utils/excelUtils/ExcelDataPopulate';
import { generateUniqueIdData } from 'utils/GetDataUtil';
import { get, isEmpty } from 'lodash';
import DisplaySettingsConst from './DisplaySettingsConst';
import { PERFORMANCE_COMMON } from 'utils/Constants';

const {
	FETCH_GET_DATA_BEGIN,
	FETCH_GET_DATA_SUCCESS,
	FETCH_GET_DATA_FAILURE,
	STORE_DATASET_BEGIN,
	STORE_DATASET_SUCCESS,
	STORE_DATASET_FAILURE,
} = DisplaySettingsConst;

const {
	CTR_SERVICE_NAME,
	ESLAM,
	ESLAD
} = PERFORMANCE_COMMON;

const emptyDatasetResponse = (response, payload) => {
	const resultRow = get(response, 'data.results.resultRow');
	if (isEmpty(resultRow)) {
		const outputKeys = Object.keys(payload).pop();
		const outputValues = payload[outputKeys].id.split(',');
		outputValues.map(key => {
			outputValues[key] = '--';
			return key;
		});
		const { results } = get(response, 'data');
		results.resultRow = [outputValues];
	}
};
const displaySettingAction = (dataParameter, path) => {
	let payload = JSON.parse(dataParameter);
	return dispatch => {
		dispatch(createAction(FETCH_GET_DATA_BEGIN));
		let timePeriodList = [];
		payload.map(item => {
			if (item.inputGroup === 'TimePeriod') {
				timePeriodList = item.id.split(',');
			}
		});
		const frequencyValue = payload.filter(item => item.inputGroup === 'InputFrequency')[0]?.value;
		const serviceNameValue = payload.filter(item => item.inputGroup === 'ServiceName')[0]?.value;
		if (((ESLAM === serviceNameValue || ESLAD === serviceNameValue) && (timePeriodList.length > 1))
			|| ((CTR_SERVICE_NAME === serviceNameValue) && (timePeriodList.length > 1
				|| (frequencyValue !== 'NA' && timePeriodList.length)))) {
			// call multi request endpoint
			return Api.getMultiplerequest(path, payload, serviceNameValue)
				.then(response => {
					emptyDatasetResponse(response, payload);
					dispatch(createAction(FETCH_GET_DATA_SUCCESS));
					return response.data;
				})
				.catch(error => {
					const errorData = {
						message:
							get(error, 'response.data.message') ||
							get(error, 'response.data'),

						status:
							get(error, 'response.data.status') ||
							get(error, 'response.status'),
					};
					dispatch(createAction(FETCH_GET_DATA_FAILURE, errorData));
					return error;
				});
		} else {
			// call normal request end
			return Api.getDataApi(path, payload)
				.then(response => {
					emptyDatasetResponse(response, payload);
					dispatch(createAction(FETCH_GET_DATA_SUCCESS));
					return response.data;
				})
				.catch(error => {
					const errorData = {
						message:
							get(error, 'response.data.message') ||
							get(error, 'response.data'),
						status:
							get(error, 'response.data.status') ||
							get(error, 'response.status'),
					};
					dispatch(createAction(FETCH_GET_DATA_FAILURE, errorData));
					return error;
				});
		}

	};
};

const updateDatasetToCosmos = (datasetObj, isRenameSheet) => {
	const datasetPayload = JSON.parse(datasetObj);
	return dispatch => {
		dispatch(createAction(STORE_DATASET_BEGIN));
		return Api.storeDataset(datasetPayload)
			.then(response => {
				updateSessionStorage('workbookId', datasetPayload.id);
				dispatch(createAction(STORE_DATASET_SUCCESS, datasetPayload));
				createHiddenSheet(datasetPayload.id, isRenameSheet);
				return response.data;
			})
			.catch(error => {
				dispatch(createAction(STORE_DATASET_FAILURE, error));
				return error;
			});
	};
};

const onDataRendered = dataset => {
	return dispatch => {
		dispatch(updateDatasetToCosmos(dataset));
	};
};

export const updateDatasetSheetName = (nameAfter, nameBefore) => (
	dispatch,
	getState,
) => {
	const state = getState();
	const dataSet = get(state, 'data.dataSet.data');
	const personaId = get(state, 'data.userInContext.user.profile.personaId');
	const reports = get(dataSet, 'reports');
	let makeApiCall = 0;
	if (reports && !isEmpty(reports)) {
		const { uniqueId } = generateUniqueIdData(personaId);
		dataSet.hiddenWorksheetID = uniqueId;
		dataSet.id = uniqueId;
		dataSet.sheetID = uniqueId;
		reports.map(item => {
			const {
				inputNodes,
				outputNodes,
				displaySetting,
				datasetRange,
			} = item;
			const datasetSplitRange = datasetRange.split('!');
			if (inputNodes.inputSheetName === nameBefore) {
				inputNodes.inputSheetName = nameAfter;
				makeApiCall += 1;
			}
			if (outputNodes.outputSheetName === nameBefore) {
				outputNodes.outputSheetName = nameAfter;
				makeApiCall += 1;
			}
			if (displaySetting.sheetName === nameBefore) {
				displaySetting.sheetName = nameAfter;
				makeApiCall += 1;
			}
			if (datasetSplitRange && datasetSplitRange[1] === nameBefore) {
				item.datasetRange = `${datasetSplitRange[0]}!${nameAfter}`;
				makeApiCall += 1;
			}
			return item;
		});
		if (makeApiCall > 0) {
			dataSet.reports = reports;
			const newObj = JSON.stringify(dataSet);
			dispatch(updateDatasetToCosmos(newObj, true));
		}
	}
};

export const onSheetNameChange = event => dispatch => {
	Excel.run(context => {
		return context
			.sync()
			.then(() => {
				const { nameAfter, nameBefore } = event;
				if (nameBefore !== nameAfter) {
					dispatch(updateDatasetSheetName(nameAfter, nameBefore));
				}
			})
			.catch(error => {
				console.log('onSheetNameCHange error=>', error);
			});
	});
};

export { displaySettingAction, onDataRendered, updateDatasetToCosmos };
