import React from 'react';
import { TextField, Dropdown, Label } from 'office-ui-fabric-react';
import { SelectAsOfSmartDate, DateRange } from './datePicker';
import Footer from './Footer/Footer';
import DropDownAutoComplete from './dropDown/DropDownAutoComplete';
import InformationIcon from './informationIcon/InformationIcon';
import RadioButton from './radioButton/RadioButton';
import ExcelCell from './excel/ExcelCell';
import SearchBox from './search/SearchBox';
import ExcelCellArray from './excel/ExcelCellArray';
import MasterTrustDropDown from './dropDown/MasterTrustDropDown';
import IndexSearchDropDownAutoComplete from './dropDown/IndexSearchDropDownAutoComplete';
import { RFCTP_SERVICES } from 'utils/Constants';

const onRenderLabel = props => {
	const { label, required, tooltipMessage } = props;
	return (
		<InformationIcon
			labelId={label}
			label={label}
			tooltipMessage={tooltipMessage}
			mandatory={required}
		/>
	);
};
const DynamicForm = props => {
	const {
		fields,
		onCancelClick,
		onIndexSearchClick,
		submitForm,
		onCellChange,
		onCellRangeChange,
		cellValue,
		sheetName,
		cellRangeValue,
		sheetRangeName,
		backWarning,
		smartDateKey,
		backWarningMessage,
		onSelectionChange,
		onMasterTrustChange,
		onDateRangeChange,
		onSmartDateChange,
		onDateChange,
		messages,
		onChangeTextField,
		onChangeRadioButton,
		serviceName,
		stepPage,
		isValidExcelField,
		isUsedCellAddress,
		isFirstRowSelected,
		isButtonDisable,
		optionalflag,
		loading,
		arrayOptionFeature,
		isValidArrayExcelField,
		isReadOnlyText
	} = props;
	const { mandatoryFields, optionalFields } = fields;
	const getTextFieldComponent = form => {
		const id = form.groupId;
		return (
			<div className="ms-Grid-row" id={id} key={id}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
					<TextField
						label={form.colText}
						name={form.name}
						value={form.value}
						className={form.control}
						required={form.mandatory}
						placeholder={form.placeholder}
						ariaLabel={form.colId}
						id={form.colId}
						onChange={onChangeTextField}
						errorMessage={form.errorMsg}
						tooltipMessage={form.colHelp}
						onRenderLabel={onRenderLabel}
					/>
				</div>
			</div>
		);
	};

	const getSearchFieldComponent = form => {
		const id = form.groupId;
		return (
			<div className="ms-Grid-row" id={id} key={id}>				
					<SearchBox
						onChangeTextField={onChangeTextField}
						onClickedIndexSearchButton={onIndexSearchClick}
						onFormData={form}
						indexSearchButtonId="settingform-searchbox"
					/>				
			</div>
		);
	};
	
	const getRadioButtonFieldComponent = form => {
		return (
			<div className="ms-Grid-row" id={form.groupId} key={form.groupId}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
					<RadioButton
						label={form.colText}
						id={form.colId}
						name={form.name}
						placeholder={form.colText}
						listItem={form.listItem}
						required={form.mandatory}
						tooltipMessage={form.colHelp}
						defaultValue={form.defaultValue || form.value}
						onChangeRadioButton={onChangeRadioButton}
						hideRadioBTN={form.showRadioBtn}
					/>
				</div>
			</div>
		);
	};
	const getMultiselectDropDown = form => {
		const { value, isMultiSelect } = form;
		let multiSelectedValues;
		if (isMultiSelect && value) {
			multiSelectedValues = value.map(item => item.key);
		}
		return (
			<div key={form.groupId} id={form.groupId}>
				<div className="ms-Grid-row">
					<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
						<Dropdown
							label={form.colText}
							id={form.colId}
							name={form.name}
							placeholder={form.colText}
							options={form.dropDowns}
							required={form.mandatory}
							multiSelect={isMultiSelect}
							errorMessage={form.errorMsg}
							onChange={onSelectionChange}
							defaultSelectedKeys={multiSelectedValues}
							value={form.value}
							calloutProps={{ directionalHintFixed: true }}
							tooltipMessage={form.toolTipMsg}
							onRenderLabel={onRenderLabel}
						/>
					</div>
				</div>
			</div>
		);
	};

	const getDropDownComponent = form => {
		const masterTrustFlag = form.groupId ==='PerformanceObject' && RFCTP_SERVICES.includes(serviceName) && arrayOptionFeature === 'YES';
		const typeFlag = form.groupId ==='PerformanceObjectType' && RFCTP_SERVICES.includes(serviceName) && arrayOptionFeature === 'NO';
		
		if(masterTrustFlag || typeFlag){
			return;
		}
		const { value, isMultiSelect } = form;
		let dropdown;
		// const isMultiSelect = form.colId === 'STP';
		if (isMultiSelect) {
			dropdown = getMultiselectDropDown(form);
		} else {
			dropdown = (
				<div key={form.groupId} id={form.groupId}>
					<div className="ms-Grid-row">
						<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
							<Dropdown
								label={form.colText}
								id={form.colId}
								name={form.name}
								placeholder={form.colText}
								options={form.dropDowns}
								required={form.mandatory}
								multiSelect={isMultiSelect}
								errorMessage={form.errorMsg}
								onChange={onSelectionChange}
								// onBlur={handleDropDownOnBlure}
								defaultSelectedKey={value ? value.key : ''}
								value={form.value}
								calloutProps={{ directionalHintFixed: true }}
								tooltipMessage={form.toolTipMsg}
								onRenderLabel={onRenderLabel}
							/>
						</div>
					</div>
				</div>
			);
		}
		return dropdown;
	};

	const getDateRangeComponent = form => {
		return (
			<div className="ms-Grid-row" key={form.groupId} id={form.groupId}>
				{form.showCTPLable ? (
					<div
						className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
						key="ctpLabel"
					>
						<Label>{form.CTPLable}</Label>
					</div>
				) : null}
				<DateRange
					field={form}
					onDateRangeChange={onDateRangeChange}
					pattern="mm/dd/yyyy"
					messages={messages}
					data={fields}
					allowTextInput
				/>
			</div>
		);
	};

	const getAutoCompleteDropDown = field => {
		if(RFCTP_SERVICES.includes(serviceName) && arrayOptionFeature === 'NO'){
		return (
			<div key={field.groupId} id={field.groupId}>
				<DropDownAutoComplete
					field={field}
					label={field.colText}
					placeholder={field.colText}
					optionsData={field.dropDowns}
					isMultiSelect={field.isMultiSelect}
					handleChange={onSelectionChange}
					isCloseMenu={field.isMultiSelect}
					arrayOptionFeature={arrayOptionFeature}

				/>
			</div>
		);
		}else if(!RFCTP_SERVICES.includes(serviceName)){
			return (
				<div key={field.groupId} id={field.groupId}>
					<DropDownAutoComplete
						field={field}
						label={field.colText}
						placeholder={field.colText}
						optionsData={field.dropDowns}
						isMultiSelect={field.isMultiSelect}
						handleChange={onSelectionChange}
						isCloseMenu={field.isMultiSelect}
						arrayOptionFeature={arrayOptionFeature}
	
					/>
				</div>
			);
		}else{
			return;
		}
	};
//1163204:Index Search
	const getIndexSearchDropDownAuto = field => {
		return (
			<div key={field.groupId} id={field.groupId}>
				<IndexSearchDropDownAutoComplete
					field={field}
					label={field.colText}
					placeholder="Select Index"
					optionsData={field.dropDowns}
					isMultiSelect={true}
					handleChange={onSelectionChange}
					isLoading={loading}
				/>
			</div>
		);
	};

	const getDatePickerComp = form => {
		return (
			<SelectAsOfSmartDate
				form={form}
				onDateChange={onDateChange}
				onSmartDateChange={onSmartDateChange}
				isDatePickerRequired
				showMonthPickerAsOverlay
				allowTextInput
				key={form.groupId}
				messages={messages}
				pattern="mm/dd/yyyy"
				isSmartDateRequired
				smartDateKey={smartDateKey}
			/>
		);
	};

	const getExcelCellComponent = () => {
		return (
			<ExcelCell
				messages={messages}
				isValidExcelField={isValidExcelField}
				isUsedCellAddress={isUsedCellAddress}
				isFirstRowSelected={isFirstRowSelected}
				handleChange={onCellChange}
				cellValue={cellValue}
				sheetName={sheetName}
				isReadOnlyText={isReadOnlyText}
			/>
		);
	};

	const getExcelCellRangeComponent = (field) => {
		
		if(RFCTP_SERVICES.includes(serviceName) && arrayOptionFeature === 'YES'){
		
		return (
			<ExcelCellArray
				handleChange={onCellRangeChange}
				cellRangeValue={cellRangeValue}
				sheetRangeName= {sheetRangeName}
				arrayOptionFeature={arrayOptionFeature}
				isValidArrayExcelField={isValidArrayExcelField}
				onFormData={fields}
				key={field.groupId}
				id={field.groupId}
			/>
		);
		}else {
			return;
		}
	};

	const getMasterTrustDropDown = field => {
		if(RFCTP_SERVICES.includes(serviceName) && arrayOptionFeature === 'NO'){
		return (
			<MasterTrustDropDown
				field={field}
				dropDownLabel={field.colText}
				defaultValue={field.value}
				valid={field.valid}
				key={field.groupId}
				id={field.groupId}
				onSelectionChange={onMasterTrustChange}
				placeholder="Enter min 3 characters of name"
				arrayOptionFeature={arrayOptionFeature}
			/>
		);
	}else if(!RFCTP_SERVICES.includes(serviceName)){
		return (
			<MasterTrustDropDown
				field={field}
				dropDownLabel={field.colText}
				defaultValue={field.value}
				valid={field.valid}
				key={field.groupId}
				id={field.groupId}
				onSelectionChange={onMasterTrustChange}
				placeholder="Enter min 3 characters of name"
				arrayOptionFeature={arrayOptionFeature}
			/>
		);
		}else{
			return;
		}
	};

	const getFields = field => {
		let component;
		const { fieldType } = field;
		const FREQUENCY_VAL= 'NA';
		const frequencyValue = fields.optionalFields.filter(item=>item.groupId==='InputFrequency')[0]?.value.value;
		switch (fieldType.toUpperCase()) {
			case 'TEXTBOX':
				component = getTextFieldComponent(field);
				break;
			case 'SEARCHBOX':
				component = getSearchFieldComponent(field);
				break;
			case 'RADIOBUTTON':
				component = getRadioButtonFieldComponent(field);
				break;
			case 'DATEPICKER':
				component = getDatePickerComp(field);
				break;
			case 'DROPDOWNSMARTSEARCH':
				component = getAutoCompleteDropDown(field);
				break;
			case 'DATERANGE':
				// Always pass showCTP=true in field whenever DateRange component required to be rendered.
				if (field.showCTP && FREQUENCY_VAL!==frequencyValue) {
					component = getDateRangeComponent(field);
				}
				break;
			case 'DROPDOWN':
				component = getDropDownComponent(field);
				break;
				case 'ARRAYRANGES':
					component = getExcelCellRangeComponent(field);
					break;
				
			case 'DROPDOWNSMARTSEARCHASYNC':
				//1163204:Index Search
			 	component =getIndexSearchDropDownAuto(field);
			 	break;		 
			case 'DROPDOWNSEARCHASYNC':
				component = getMasterTrustDropDown(field);
				break;
		}
		return component;
	};
	return (
		<div className="ms-Grid" dir="ltr">
			<form className="footer-margin">
				{mandatoryFields.map(field => {
					return getFields(field);
				})}
				{optionalFields.map(field => {
					return getFields(field);
				})}
				{getExcelCellComponent()}
				<div className="ms-Grid-row">
					<Footer
						showBackButton
						backWarning={backWarning}
						backButtonText="Back"
						backWarningMsg={backWarningMessage}
						backButtonId="settingform-backbutton"
						onClickedBackButton={onCancelClick}
						indexSearchButtonId="settingform-indexsearchbutton"
						onClickedIndexSearchButton={onIndexSearchClick}
						onClickedNextButton={submitForm}
						showNextButton
						showSearchButton
						nextButtonId="settingform-nextbutton"
						nextButtonText="Next"
						serviceName={serviceName}
						stepPage={stepPage}
						isButtonDisable={isButtonDisable}
					/>
				</div>
			</form>
		</div>
	);
};

export default DynamicForm;
