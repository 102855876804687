import {
	SetRuntimeVisibleHelper,
	ensureStateInitialized,
} from 'utils/excelUtils/office-apis-helpers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createHashHistory } from 'history';

export function getGlobal() {
	console.log('init globals for command buttons');
	if (typeof window !== 'undefined') {
		return window;
	}
	if (typeof global !== 'undefined') {
		return global;
	}
	return undefined;
}

const g = getGlobal();

export function btnOpenTaskpane(event, isLogoutClick) {
	ensureStateInitialized(true);
	const appHistory = createHashHistory();
	SetRuntimeVisibleHelper(true);
	g.state.isTaskpaneOpen = true;
	const isSignedIn = localStorage.getItem('loggedIn') === 'yes';
	if (isSignedIn && !isLogoutClick) {
		// sessionStorage.setItem('isRenameSheet', true);
		appHistory.push('/');
	} else {
		appHistory.push('/login');
	}
	event.completed();
}

export function btnCloseTaskpane(event) {
	SetRuntimeVisibleHelper(false);
	g.state.isTaskpaneOpen = false;
	event.completed();
}

export function btnloginservice(event) {
	btnOpenTaskpane(event, false);
}

export function btnlogoutservice(event) {
	btnOpenTaskpane(event, true);
}

export function btnnthomeservice(event) {
	const appHistory = createHashHistory();
	// sessionStorage.setItem('isRenameSheet', true);
	SetRuntimeVisibleHelper(true);
	appHistory.replace('/');
	event.completed();
}

export function btncreatedatasetservice(event) {
	const appHistory = createHashHistory();
	SetRuntimeVisibleHelper(true);
	appHistory.push('/create-new-dataset');
	event.completed();
}

export function btndatasetmanagerservice(event) {
	const appHistory = createHashHistory();
	SetRuntimeVisibleHelper(true);
	appHistory.push('/dataset-manager');
	event.completed();
}

export function btnfeedbackservice(event) {
	const appHistory = createHashHistory();
	SetRuntimeVisibleHelper(true);
	// window.open(
	// 	'https://northerntrustfeedback.allegiancetech.com/cgi-bin/qwebcorporate.dll?idx=M9EVF9',
	// 	'_blank',
	// );
	appHistory.push('/feedback');
	event.completed();
}

// the add-in command functions need to be available in global scope
g.btnloginservice = btnloginservice;
g.btnlogoutservice = btnlogoutservice;
g.btnnthomeservice = btnnthomeservice;
g.btncreatedatasetservice = btncreatedatasetservice;
g.btndatasetmanagerservice = btndatasetmanagerservice;
g.btnfeedbackservice = btnfeedbackservice;
g.btnopentaskpane = btnOpenTaskpane;
g.btnclosetaskpane = btnCloseTaskpane;
