const ExcelCommonTexts = {
	createCellLabel: 'Select Cell',
	createSheetLabel: 'Select Sheet',
	createCellRangeLabel: 'Type Range',
	createSheetRangeLabel: 'Type Sheet Name',
	emptyCellIdErrorMsg: 'Please specify cell number for inputs',
	emptySheetNameErrorMsg: 'Please specify sheet name for inputs',
	cellIdToolTipMsg: 'Specify cell location for input headers',
	sheetNameToolTipMsg: 'Specify sheet for input headers',
	blankCellAddressMsg: 'Please select blank cell or row for headers',
	firstRowReserveMsg:
		'Row 1 is reserved for optional report header configured in display setting task pane.',
	emptyArrayCellIdErrorMsg: 'Please specify valid cell range for array inputs',
	emptyArraySheetNameErrorMsg: 'Please specify valid sheet name for array inputs',
};

export default ExcelCommonTexts;
