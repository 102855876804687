import React, { memo } from 'react';
import { TextField } from 'office-ui-fabric-react';
import InformationIcon from '../informationIcon/InformationIcon';
import ExcelCommonTexts from './ExcelCellText';
import { validateArrayCellIdSheetName } from 'utils/validationRules';
const ExcelCellArray = props => {
	const {
		handleChange,
		cellRangeValue,
		sheetRangeName,
		arrayOptionFeature,
		isValidArrayExcelField,
		onFormData
	} = props;
	let regInteger = /^-?\d+$/;
	const regAlpha = /[a-zA-Z]/;
	const isArrayCellValid = cellRangeValue && cellRangeValue !== '' && cellRangeValue.trim().split(":");
	let isValidArrayCellId = isArrayCellValid && isArrayCellValid.length===2 
	&& isArrayCellValid[0].trim() && regAlpha.test(isArrayCellValid[0].trim().charAt(0))
	&& regInteger.test(isArrayCellValid[0].trim().charAt(isArrayCellValid[0].trim().length-1))
	&& isArrayCellValid[1].trim() && regAlpha.test(isArrayCellValid[1].trim().charAt(0))
	&& regInteger.test(isArrayCellValid[1].trim().charAt(isArrayCellValid[1].trim().length-1));


	const isValidArraySheetName = sheetRangeName && sheetRangeName !== '';
	let isValidArrayCellIdSheetName ;
	if(isValidArraySheetName && isValidArrayCellId ){
		isValidArrayCellIdSheetName=validateArrayCellIdSheetName(cellRangeValue, sheetRangeName) ? true : false ;
	}
	const onRenderLabel = items => {
		const { label, required, tooltipMessage } = items;
		return (
			<InformationIcon
				labelId={label}
				label={label}
				tooltipMessage={tooltipMessage}
				mandatory={required}
			/>
		);
	};
	return (
		//flag &&	<>
		<>
			<div className="ms-Grid-row">
			<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					<TextField
						label={
							ExcelCommonTexts.createSheetRangeLabel
						}
						id="sheet-range-name"
						name="sheetRangeName"
						placeholder={
							ExcelCommonTexts.createSheetRangeLabel
						}
						required
						value={sheetRangeName}
						onChange={handleChange}
						errorMessage={
							sheetRangeName
								? ''
								: ExcelCommonTexts.emptyArraySheetNameErrorMsg
						}
						tooltipMessage={
							ExcelCommonTexts.sheetNameToolTipMsg
						}
						onRenderLabel={onRenderLabel}
					/>
				</div>	
			<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					<TextField
						label={
							ExcelCommonTexts.createCellRangeLabel
						}
						id="cell-range-no"
						name="cellRangeNumber"
						placeholder={
							ExcelCommonTexts.createCellRangeLabel
						}
						required
						value={cellRangeValue}
						onChange={handleChange}
						errorMessage={
							isValidArrayCellIdSheetName && isValidArrayExcelField
								? ''
								: ExcelCommonTexts.emptyArrayCellIdErrorMsg
						}
						tooltipMessage={
							ExcelCommonTexts.cellIdToolTipMsg
						}
						onRenderLabel={onRenderLabel}
					/>
				</div>
				
			</div>
		</>
	);
};

export default memo(ExcelCellArray);
