/* global Office */
import * as React from 'react';
import { ApiService } from '../../services/ApiService';
import { AuthService } from '../../services/AuthService';

class AuthContainer extends React.Component {
	constructor(props) {
		super(props);
		this.authService = new AuthService();
		this.apiService = new ApiService();
		this.shouldCancel = false;
	}

	componentDidMount() {
		this.getUser();
	}

	componentWillUnmount() {
		this.shouldCancel = true;
	}

	login = () => {
		this.authService.login();
	};

	renewToken = () => {
		this.authService
			.renewToken()
			.then(() => {
				this.getUser();
			})
			.catch(error => {
				return error;
			});
	};

	logout = () => {
		this.authService.logout();
	};

	getUser = () => {
		this.authService.getUser().then(user => {
			if (user && !this.shouldCancel) {
				Office.onReady().then(() => {
					Office.context.ui.messageParent(
						JSON.stringify({ status: 'success', result: user }),
					);
				});
			} else {
				this.login();
			}
		});
	};

	render() {
		return <p>Redirecting!</p>;
	}
}
export default AuthContainer;
