import { get, filter, isEmpty } from 'lodash';
import { formatDate } from './dateUtil';
import { ARRAY_DROPDOWN_NA, ARRAY_VAL_CONDITINAL } from '../containers/performance/PerformanceConst';
import { ValidationState } from 'office-ui-fabric-react';
const rules = {
	reportTitle: /^[^*?\\:[\]\\/]+$/,
	letterWithNumber: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
	required: value => {
		value = value && typeof value === 'string' ? value.trim() : value;
		return !value;
	},
};

// below method validate duplicate dataset title
export const validateDuplicate = (existingData, newTitle, id) => {
	const { reports } = existingData;
	const existingReport = filter(reports, item => {
		return item.reportName === newTitle && item.reportId !== id;
	});
	return !isEmpty(existingReport);
};

// validate report title for special charaters
export const validateReportTitle = (value, errorMessage, dataset, id) => {
	let message = '';
	if (rules.required(value)) {
		message = get(errorMessage, 'notEmtpyErrorMsg');
	} else if (!rules.reportTitle.test(value)) {
		message = get(errorMessage, 'invalidErrorMsg');
	} else if (validateDuplicate(dataset, value, id)) {
		message = get(errorMessage, 'duplicateErrMsg');
	}
	return message;
};

export const checkValidity = item => {
	const value = get(item, 'value');
	const isValidValue = !value || value === '' || value.length === 0;
	return item.mandatory && isValidValue;
};

export const checkArrayValidity = (item, arrayOptionFeature) => {
	if((arrayOptionFeature === 'YES' && ARRAY_DROPDOWN_NA.includes(item.colText))){
		item.valid = true;
		item.errorMsg = '';
		return item.mandatory && false;
	}
	if(arrayOptionFeature === 'NO' && ARRAY_VAL_CONDITINAL.includes(item.colText)){
		item.valid = true;
		item.errorMsg = '';
		return item.mandatory && false;
	}
	return item.mandatory && true;
};

export const checkLengthValidity = item => {
	const value = get(item, 'value');
	const isValidLength = !value || value.length < 3;
	return item.mandatory && isValidLength;
};

export const validateDateRange = item => {
	const { valid, value } = item;
	let { startDate, endDate } = value;
	let flagSDDate =true;
	let flagEDDate =true;
	if(startDate){
		flagSDDate = validateFutureDate(startDate);
	}
	if(endDate){
		flagEDDate = validateFutureDate(endDate);
	}
	startDate = startDate ? formatDate(startDate, 'mm/dd/yyyy') : null;
	endDate = endDate ? formatDate(endDate, 'mm/dd/yyyy') : null;
	valid.startDate = startDate !== undefined && startDate !== null;
	valid.startDateErrorMsg = !startDate ? `Please provide Start date` : '';
	valid.endDate = endDate !== undefined && endDate !== null;
	valid.endDateErrorMsg = !endDate ? `Please provide End date` : '';
	const startDateValidate = new Date(startDate) < new Date(endDate);
	const showReturnStatus = !!startDate && !!endDate && startDateValidate;
	return valid.startDate && valid.endDate && showReturnStatus && flagSDDate && flagEDDate;
};

export const validateFieldValues = (fields, indexSearchText, arrayOptionFeature) => {
	if (indexSearchText === "indexSearchText") {
		return filter(fields, item => {
			const isValidField = item.groupId === 'searchText' ? checkValidity(item) : false;
			const isValidLength = item.groupId === 'searchText' ? checkLengthValidity(item) : false;
			if (item.groupId === 'searchText') {
				if (isValidField) {
					item.valid = false;
					item.errorMsg = `Please enter text for Index search.`;
				} else if (isValidLength) {
					item.valid = false;
					item.errorMsg = `Please enter at least 3 characters for Index search.`;
				} else {
					item.valid = true;
					item.errorMsg = '';
				}
				return (!item.valid && item.mandatory);
			}
			return (false);
		});
	} else {
		return filter(fields, item => {
			const isValidField = checkValidity(item);
			const isValidArrayField = checkArrayValidity(item, arrayOptionFeature);
			const isDateRangeValid =
				item.fieldType === 'DateRange' ? validateDateRange(item) : true;
			//if (item.groupId !== 'searchText' || (arrayOptionFeature === 'YES' && !ARRAY_DROPDOWN_NA.includes(item.colText))) {
				if (item.groupId !== 'searchText' && isValidArrayField) {
				if (isValidField && item.fieldType !== 'DateRange') {
					item.valid = false;
					item.errorMsg = `Please select ${item.colText}`;
				} else if (item.fieldType !== 'DateRange') {
					item.valid = true;
					item.errorMsg = '';
				}
				//No, MAter Trust & Account Grp::  ValidationState
				
				//YES, ArrayRange, ObjectTYpe
				return !isDateRangeValid || (!item.valid && item.mandatory);
			}
			return false;
		});
	}
};

export const validateTemplateField = (fields, groupId) => {
	return filter(fields, item => {
		// const isValidField = checkValidity(item);
		if (item.groupId === groupId) {
			item.valid = false;
			item.errorMsg =
				groupId === 'Template' ? 'No Template Available' : null;
			item.value = '';
			item.dropDowns = [];
		}
	});
};

export const validateCellIdSheetName = (cellValue, sheetName) => {
	const isValidCellId = cellValue && cellValue !== '';
	const isValidSheetName = sheetName && sheetName !== '';
	return { isValidCellId, isValidSheetName };
};

export const validateArrayCellIdSheetName = (cellArrayValue, sheetArrayName) => {
	let regInteger = /^-?\d+$/;
	const regAlpha = /[a-zA-Z]/;
	const isArrayCellValid = cellArrayValue && cellArrayValue !== '' && cellArrayValue.trim().split(":");

	let isValidArrayCellId = isArrayCellValid && isArrayCellValid.length===2 
	&& isArrayCellValid[0].trim() && regAlpha.test(isArrayCellValid[0].trim().charAt(0))
	&& regInteger.test(isArrayCellValid[0].trim().charAt(isArrayCellValid[0].trim().length-1))
	&& isArrayCellValid[1].trim() && regAlpha.test(isArrayCellValid[1].trim().charAt(0))
	&& regInteger.test(isArrayCellValid[1].trim().charAt(isArrayCellValid[1].trim().length-1));

	let remainingCharacterFirst = isArrayCellValid[0].match(/[0-9]/)
	let firstDigit = isArrayCellValid[0].substr(0,remainingCharacterFirst.index); 
	let afterFirst = isArrayCellValid[0].substr(remainingCharacterFirst.index); 
	let isValidFirstString = regInteger.test(afterFirst);
	let remainingCharacterSecond = isArrayCellValid[1].match(/[0-9]/)
	let secondDigit = isArrayCellValid[1].substr(0,remainingCharacterSecond.index); 
	let afterSecond = isArrayCellValid[1].substr(remainingCharacterSecond.index); 
	
	let isValidSecondString = regInteger.test(afterSecond);
	const isValidRange =  afterFirst < 1048576 && afterSecond < 1048576 && firstDigit.length<4 && secondDigit.length<4; 
	const isValidArraySheetName = sheetArrayName && sheetArrayName !== '';
	
	return isValidArrayCellId && isValidArraySheetName && isValidRange && isValidSecondString && isValidFirstString;
};


// validate cell ID should contain atleast one letter and one number
export const validateCellValue = value => {
	let isValid = true;
	if (rules.required(value) || !rules.letterWithNumber.test(value)) {
		isValid = false;
	}
	return isValid;
};

export const validateDateDiffError = value => {
	const startDate = get(value, 'startDate');
	const endDate = get(value, 'endDate');
	let flagSDDate =true;
	let flagEDDate =true;
	if(startDate){
		flagSDDate = validateFutureDate(startDate);
	}
	if(endDate){
		flagEDDate = validateFutureDate(endDate);
	}
	const dateValue = startDate && endDate;
	const diff = Math.abs(startDate - endDate);
	const noOfDays = diff / (1000 * 60 * 60 * 24);
	return dateValue && noOfDays > 365 && flagSDDate && flagEDDate;;
};

export const validateFutureDate = (GivenDate) => {
	var CurrentDate = new Date();
	GivenDate = new Date(GivenDate);
	return GivenDate < CurrentDate;
};

