import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { DefaultColors } from 'styles/colors';
import { Api } from 'api/Api';
import InformationIcon from '../informationIcon/InformationIcon';
import './MasterTrustDropDown.scss';

const MasterTrustDropDown = props => {
	const { field, dropDownLabel, valid, defaultValue, placeholder, arrayOptionFeature } = props;
	const [selectedValue, setSelectedValue] = useState(defaultValue || null);
	let flag = arrayOptionFeature === 'NO' ? true : false; 
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: 'white',
			fontFamily: 'Segoe UI',
			fontSize: 12,
			fontWeight: 400,
			height: state.isMulti ? 'auto' : 26,
			minHeight: 28,
			cursor: 'pointer',
			borderColor: field.valid ? '#444' : DefaultColors.ErrorColor,
			borderRadius: 2,
		}),
		menu: provided => ({
			...provided,
			marginTop: 2,
			overflow: 'auto',
		}),
		option: (styles, { isDisabled }) => {
			return {
				...styles,
				cursor: isDisabled ? 'not-allowed' : 'default',
				fontFamily: 'Segoe UI',
				fontSize: 12,
				fontWeight: 400,
				boxSizing: 'border-box',
				display: 'flex',
				textAlign: 'left',
				verticalAlign: 'top',
				paddingTop: 0,
				paddingRight: 8,
				paddingBottom: 0,
				paddingLeft: 8,
				height: 26,
				alignItems: 'center',
				minHeight: 26,
				overflowWrap: 'break-word',
				userSelect: 'none',
				outline: 'transparent',
				':active': {
					...styles[':active'],
				},
			};
		},
		container: base => ({
			...base,
			fontFamily: 'Segoe UI',
			fontSize: 12,
			fontWeight: 400,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			color: '#444',
		}),
		valueContainer: base => ({
			...base,
			minHeight: 26,
			position: 'static',
			boxShadow: 'none',
			color: '#444',
			cursor: 'pointer',
			display: 'flex',
		}),
		placeholder: styles => ({ ...styles, fontStyle: 'italic' }),
		input: styles => ({
			...styles,
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			boxSizing: 'border-box',
		}),
		singleValue: styles => ({ ...styles }),
		multiValue: styles => {
			return {
				...styles,
				backgroundColor: '#e2fce3',
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),
	};

	useEffect(() => {
		setSelectedValue(defaultValue);
	}, [defaultValue]);

	// handle selection
	const handleChange = (event, option) => {
		const data = {
			...event,
			elementId: field.groupId,
			key: event.id,
			label: event.masterTrustNameId,
			text: event.masterTrustNameId,
			value: event.id,
		};
		setSelectedValue(data);
		if (props.onSelectionChange) {
			props.onSelectionChange(data, option);
		}
	};

	// load options using API call
	const loadOptions = (searchText, callback) => {
		if (!searchText || searchText.trim().length < 3) {
			return callback([]);
		}
		return Api.getMasterTrust(searchText.trim()).then(res =>
			callback(res.data),
		);
	};

	return (
		<div className="ms-Grid-row dropdown-search">
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
				<InformationIcon
					labelId={field.colId}
					label={dropDownLabel}
					tooltipMessage={field.toolTipMsg}
					mandatory={field.mandatory}
				/>
			</div>
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
				<AsyncSelect
					id={field.colId}
					cacheOptions
					defaultOptions
					value={selectedValue}
					placeholder={placeholder || field.colText}
					getOptionLabel={e => e.masterTrustNameId}
					getOptionValue={e => e.id}
					loadOptions={loadOptions}
					onChange={handleChange}
					theme={theme => ({
						...theme,
						borderRadius: 0,
						colors: {
							...theme.colors,
							...theme.palette,
							primary25: '#e2fce3',
							primary: '#2d8031',
						},
					})}
					styles={colourStyles}
				/>
				{!valid ? <p className="errorClass">{field.errorMsg}</p> : null}
			</div>
		</div>
	);
};

export default MasterTrustDropDown;
