import { find } from 'lodash';

const paddingData = data => {
	return data < 10 ? `0${data}` : data;
};
const isDate = dateStr => {
	return Object.prototype.toString.call(dateStr) === '[object Date]';
};
export const formatDate = (date, pattern) => {
	if (!isDate(date)) {
		date = new Date(date);
	}
	const month =
		date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
	const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
	const fullYear = date.getFullYear();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();
	let dateValue;
	switch (pattern) {
		case 'mm/dd/yyyy':
			dateValue = `${month}/${day}/${fullYear}`;
			break;
		case 'yyyymmddhhmmss':
			dateValue = `${fullYear}${month}${day}${paddingData(
				hours,
			)}${paddingData(minutes)}${paddingData(seconds)}`;
			break;
		case 'mm/dd/yyyy hh:mm:ss':
			dateValue = `${month}/${day}/${fullYear} ${paddingData(
				hours,
			)}:${paddingData(minutes)}:${paddingData(seconds)}`;
			break;
	}
	return dateValue;
};
export const lastDateOfMidYear = () => {
	const date1 = new Date(Date.now());
	const fullYear = date1.getFullYear();
	const day = date1.getDay();
	const months = date1.toLocaleString('en-US', { month: 'short' });
	const dateValue = `${day} ${months} ${fullYear}`;
	var makeDate2 = new Date(dateValue);
	makeDate2.setMonth(makeDate2.getMonth() - 1);
	makeDate2.setDate(0)
	return new Date(makeDate2);
};

export const lastDateOfMidMonth = () => {
	const date1 = new Date(Date.now());
	const fullYear = date1.getFullYear();
	const day = date1.getDay();
	const months = date1.toLocaleString('en-US', { month: 'short' });
	const dateValue = `${day} ${months} ${fullYear}`;
	var makeDate1 = new Date(dateValue);
	makeDate1.setMonth(makeDate1.getMonth());
	makeDate1.setDate(0)
	return new Date(makeDate1);
}
export const lastDateOfLastYear = () => {
	const dateObj = new Date();
	return new Date(dateObj.getFullYear() - 1, 11, 31);
};
export const lastDateofLastMonth = () => {
	const dateObj = new Date();
	dateObj.setMonth(dateObj.getMonth(), 0);
	return new Date(dateObj);
};

export const lastQuarterDates = () => {
	const now = new Date();
	const quarter = Math.floor(now.getMonth() / 3);
	const firstDate = new Date(now.getFullYear(), quarter * 3, 1);
	return new Date(firstDate.getFullYear(), firstDate.getMonth(), 0);
};

export const previousBusinessDate = () => {
	const dateObj = new Date();
	let businessDay = dateObj.getDay();
	if (businessDay < 2) {
		if (businessDay === 0) {
			businessDay = 2;
		} else {
			businessDay = 3;
		}
	} else {
		businessDay = 1;
	}
	return new Date(new Date().setDate(new Date().getDate() - businessDay));
};

export const getSmartDate = option => {
	let newDate;
	if (option.key === 'last_month') {
		newDate = lastDateofLastMonth();
	} else if (option.key === 'last_quarter') {
		newDate = lastQuarterDates();
	} else if (option.key === 'last_year') {
		newDate = lastDateOfLastYear();
	} else if (option.key === 'previous_business_day') {
		newDate = previousBusinessDate();
	} else {
		newDate = null;
	}
	return newDate;
};

export const getSmartDateData = (MandatoryFields, newDate, smartDateKey) => {
	find(MandatoryFields, item => {
		if (item.groupId === 'ReferenceDate') {
			item.value = newDate;
			item.smartDateKey = smartDateKey;
			item.valid = true;
			item.errorMsg = '';
		}
		return item.groupId === 'ReferenceDate';
	});
	return MandatoryFields;
};

export const convertDateTimeGBFormat = (dateTime, isDateTime) => {
	const dateTimeValue = new Date(dateTime);
	const dateFormatObj = {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	};
	if (isDateTime) {
		dateFormatObj.hour = 'numeric';
		dateFormatObj.minute = 'numeric';
		dateFormatObj.hour12 = true;
	}
	const formatDateTime = dateTimeValue.toLocaleString('en-GB', dateFormatObj);
	const splitArray = formatDateTime.split(',');
	const date = splitArray[0];
	const time = splitArray[1];
	return { date, time };
};

// returns date format based on browser language setting
export const getOriginDateFormat = () => {
	const { navigator } = window;
	const language = navigator.language || navigator.browserLanguage;
	return language === 'en-GB' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
};

export const updateNodeDateChange = (mandatoryFields, date, type) => {
	find(mandatoryFields, item => {
		if (item.fieldType === 'DateRange') {
			if (type === 'startDate') {
				item.valid = {
					...item.valid,
					[type]: true,
					startDateErrorMsg: '',
				};
				item.errorMsg = '';
				item.value = {
					...item.value,
					[type]: date,
				};
			}
			if (type === 'endDate') {
				item.valid = {
					...item.valid,
					[type]: true,
					endDateErrorMsg: '',
				};
				item.value = {
					...item.value,
					[type]: date,
				};
			}
		}
		return item.fieldType === 'DateRange';
	});
	return mandatoryFields;
};
