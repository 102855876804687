import axios from 'api';
import { PERFORMANCE_COMMON } from 'utils/Constants';
import { getLocalStorageItem } from 'utils/CommonUtil';
import { EXTERNALROOTURL, Constants } from 'utils/Constants';

// Below object exposes all API's from one single place to the entire application
export const Api = {
	getMasterTrust: text => {
		const searchText = text ? text.trim() : '';
		const path = `/xde/getMasterTrustSearch?searchtext=${searchText}`;
		return axios.request({
			method: 'GET',
			url: path,
		});
	},
	getWelcomeMessages: () => {
		const path = `/xde/getWelcomeMessages`;
		return axios.request({
			method: 'GET',
			url: path,
		});
	},
	retrieveDataSet: id => {
		return axios.request({
			method: 'GET',
			url: `/xde/getDataSet?id=${id}`,
		});
	},
	retriveServiceList: () => {
		return axios.request({
			method: 'GET',
			url: '/xde/getServiceList',
		});
	},
	retriveServiceData: (path, serviceName) => {
		let url = path;
		if (!path) {
			url = `/xde/getMetadata?serviceName=${serviceName}`;
		}
		return axios.request({
			method: 'GET',
			url,
		});
	},
	getAccountGroups: (masterTrust, userId) => {
		return axios.request({
			method: 'GET',
			url: `/xde/getAccountsByMasterTrust?masterTrustId=${masterTrust}`,
		});
	},
	//1163204:Index Search
	getIndexSearchData: (requestParameterValues) => {
		return axios.request({
			method: 'GET',
			url: `/xde/getIndexList${requestParameterValues}`,
		});
	},
	//1163208:Universe Search
	getUniverseName: (requestParameterValues) => {
		return axios.request({
			method: 'GET',
			url: `xde/universeprovider/${requestParameterValues}`,
		});
	},
	//1163208:CompositeName Search
	getCompositeID: (requestParameterValues) => {
		return axios.request({
			method: 'GET',
			url: `/xde/getCompositeSearch?${requestParameterValues}`,
		});
	},
	getDataApi: (path, payload) => {
		return axios.request({
			method: 'POST',
			url: path,
			headers: {},
			data: payload,
		})
	},
	getMultiplerequest: async (path, request, serviceNameValue) => {
		let UpdatedFreqRequest;
		let updateTPRemoveRequest;
		let timePeriodValue = [];
		let timePeriodRequests = []
		const {
			CTR_SERVICE_NAME,
			ESLAM,
			ESLAD
		} = PERFORMANCE_COMMON;
		// Collect list of TimePeriod ID i.e. M1,M3,YTD
		request.map(item => {
			if (item.inputGroup === 'TimePeriod') {
				timePeriodValue = item.id.split(',');
			}
		});
		//collect Frequency value 
		const frequencyValue = request.filter(item => item.inputGroup === 'InputFrequency')[0]?.value;
		
		if ((ESLAM === serviceNameValue || ESLAD === serviceNameValue) && timePeriodValue.length > 0) {
			// Make multiple request json based on List of Time period
			timePeriodValue.forEach(i => {
				timePeriodRequests.push(request.map(item => (item.inputGroup === 'TimePeriod' ? { ...item, id: i } : item)));
			})
		
		}
		if (CTR_SERVICE_NAME === serviceNameValue) {
		if (frequencyValue !== 'NA' && timePeriodValue.length) {
			// Collect Only TimePeriod Request with without Start Date , End Date and with NA frequency
			UpdatedFreqRequest = [...request.map(item => (item.inputGroup === 'InputFrequency' ? { ...item, value: 'NA' } : item))
				.filter((item) => item.inputGroup !== "CTP1")];
			// Collect Only Frequency Request with Start Date and End Date
			updateTPRemoveRequest = [...request.filter((item) => item.inputGroup !== "TimePeriod")];

			// Make multiple request json based on List of Time period
			timePeriodValue.forEach(i => {
				timePeriodRequests.push(UpdatedFreqRequest.map(item => (item.inputGroup === 'TimePeriod' ? { ...item, id: i } : item)));
			})
			// Now push the Frequency request to FInal list of request.
			timePeriodRequests.push(updateTPRemoveRequest)
		
		} else if (timePeriodValue.length > 1) {
			// Make multiple request json based on List of Time period
			timePeriodValue.forEach(i => {
				timePeriodRequests.push(request.map(item => (item.inputGroup === 'TimePeriod' ? { ...item, id: i } : item)));
			})
		}
	}
		let allRequestsCTR = [];
		timePeriodRequests.map(requests => {
			allRequestsCTR.push(axios.request({
				method: 'POST',
				url: path,
				headers: {},
				data: requests,
			}))
		})

		try {
			const responses = await Promise.all(allRequestsCTR);

			let allResponse = [];
			let finalResponse = responses[0];
			responses.map((responses) => {
				allResponse.push(responses.data.results.resultRow);
			});

			finalResponse.data.results.resultRow = allResponse.flat();
			return finalResponse;
		}
		catch (err) {
			console.log('User creation error: ', err);
		}
	},

	storeDataset: payload => {
		return axios.request({
			method: 'POST',
			url: '/xde/addDataSet',
			headers: {},
			maxContentLength: Infinity,
			maxBodyLength: Infinity,
			data: payload,
		});
	},
	getSettings: () => {
		return axios.request({
			method: 'GET',
			url: '/xde/settings',
		});
	},
	getPersonaId: () => {
		return axios.request({
			method: 'GET',
			url: '/xde/getPassportProfile',
		});
	},
	getRefreshToken: () => {
		const validRootURL = Constants.clientRoot;
		const flag = EXTERNALROOTURL.includes(validRootURL) ? 'External' : 'Internal';
		const token = getLocalStorageItem('authToken');
		const refreshToken = getLocalStorageItem('refresh_token');
		const Headerdata = { Ntrsusertype: flag, refresh_token: refreshToken, Authorization: token };

		return axios.request({
			method: 'GET',
			url: '/xde/getRefreshToken',
			headers: { ...Headerdata },

		});
	},
	getTemplates: (masterTrust, serviceName) => {
		const path = `xde/getTemplates/${masterTrust}?serviceName=${serviceName}`;
		return axios.request({
			method: 'GET',
			url: path,
		});
	},
};
