import * as Oidc from 'oidc-client';
import { Constants, ENVIRONMENT , EXTERNALROOTURL} from 'utils/Constants';
import { checkEnv } from 'utils/config';

export class AuthService {
	constructor() {
		const env = checkEnv();
		const validRootURL = Constants.clientRoot;
		const flag = EXTERNALROOTURL.includes(validRootURL) ? true : false;
		const clientIDs = flag ? ENVIRONMENT[env].clientId_External : ENVIRONMENT[env].clientId;
		const settings = {
			authority: ENVIRONMENT[env].stsAuthority, // Constants.stsAuthority,
			client_id: clientIDs, // Constants.clientId,
			redirect_uri: `${Constants.clientRoot}login/signin-callback.html`,
			silent_redirect_uri: `${Constants.clientRoot}login/silent-renew.html`,
			post_logout_redirect_uri: `${Constants.clientRoot}login/signout.html`,
			response_type: Constants.type,
			scope: Constants.clientScope,
			loadUserInfo: true,
			state: true,
			stateStore: new Oidc.WebStorageStateStore({ store: localStorage }),
			userStore: new Oidc.WebStorageStateStore({ store: localStorage }),
			metadata: {
				// Constants.endSessionEndPoint 
				//${ENVIRONMENT[env].endSessionEndPoint}
				end_session_endpoint: `${ENVIRONMENT[env].endSessionEndPoint}?TargetResource=${Constants.clientRoot}login/signout.html`,
				authorization_endpoint: ENVIRONMENT[env].authEndpoint, // Constants.authorisationEndpoint,
				// token_endpoint: 'https://nam-a.ntrs.com/as/token.oauth2',
				token_endpoint: ENVIRONMENT[env].tokenEndpoint,
				issuer: ENVIRONMENT[env].issuer,
				revocation_endpoint: ENVIRONMENT[env].revocation_endpoint,
				userinfo_endpoint: ENVIRONMENT[env].userinfo_endpoint,
			},
		};
		this.userManager = new Oidc.UserManager(settings);
	}

	getUser = () => {
		return this.userManager.getUser();
	};

	getLoggedInUser = () => {
			return this.userManager
			.getUser()
			.then(user => {
				return user; 
			})
				 // updateLocalStorage('authToken', get(user, 'access_token'))
			.catch(error => console.log('user retrieval failed!!', error));
	};

	login = () => {
		return this.userManager.signinRedirect();
	};

	renewToken = () => {
		// return this.userManager.signinSilent();
		return this.userManager
			.signinSilent()
			.then(user => user) // updateLocalStorage('authToken', get(user, 'access_token'))
			.catch(error => console.log('token refresh failed!!'));
	};

	logout = () => {
		return this.userManager.signoutRedirect();
	};
}
