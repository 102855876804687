const ErrorMessageTexts = {
	modalTexts: {
		btnYes: 'OK',
		btnNo: 'NO',
		title: 'Sorry!',
		btnDeleteDataset: 'Clear Dataset',
		description:
			'We are unable to get your data! Please double check your input settings and try again. If the problem persists please contact a member of your client servicing team.',
		runAllFailMsg:
			'Not all of your datasets refreshed successfully! Please double check the input settings for failed dataset(s) and try again. If the problem persists please contact a member of your client servicing team.',
	},
};
export default ErrorMessageTexts;
